<template>
  <div class="content">
    <div>
      <el-form ref="staffRef" :inline="true"  size="medium" :model="dataForm" label-width="90px">
        <el-form-item label="结算时间：">
          <el-date-picker
              type="daterange"
              v-model="time"
              @change="getTime"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <div style="display: inline-block;padding-left: 20px; padding-bottom: 20px;">
          <el-button type="primary" @click="getTable" size="medium">查询</el-button>
          <el-button plain @click="getReset" size="medium">重置</el-button>
        </div>
      </el-form>
    </div>
    <div>
      <el-table
          :data="tableData"
          border
          :stripe="true"
          style="width: 100%">
        <el-table-column prop="date" label="编号" align="center">
          <template slot-scope="scope">
            <span>{{
                scope.$index + 1 + (page.pageNo - 1) * page.pageSize
              }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cleaningAccount" width="160px" label="结算帐号" align="center"></el-table-column>
        <el-table-column prop="amountCleaning" label="结算金额" align="center"></el-table-column>
        <el-table-column prop="presidentName" width="140px" label="会长名称" align="center"></el-table-column>
        <el-table-column prop="userName" width="140px" label="用户名" align="center"></el-table-column>
        <el-table-column prop="cleanigTime" width="160px" label="开始时间" align="center"></el-table-column>
        <el-table-column prop="disposeTime" width="160px" label="处理时间" align="center"></el-table-column>
        <!-- <el-table-column prop="cleaningWay" width="160px" label="结算方式" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.cleaningWay == 1">银行卡</span>
            <span v-else>支付宝</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="statusCleaning" label="结算状态" width="100px" align="center">
          <template slot-scope="scope">
<!--            1、申请中/2、已驳回/3、已处理/4、取消申请
            <el-tag size="mini" v-if="scope.row.status == 1">申请中</el-tag>
            <el-tag size="mini" v-else-if="scope.row.status == 2"  type="warning">已驳回</el-tag>
            <el-tag size="mini" v-else-if="scope.row.status == 3" type="success">已处理</el-tag>
            <el-tag size="mini" v-else type="info">取消申请</el-tag>-->
            <el-tag size="mini" v-if="scope.row.statusCleaning == 2" type="success">已结算</el-tag>
            <el-tag size="mini" v-else type="info">待结算</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createdDatetime" width="160px" label="创建时间" align="center"></el-table-column>
        <el-table-column label="操作" width="120px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.statusCleaning != 2" style="color: #1D90FF;cursor: pointer" @click="getRowRecord(scope.row)">结算</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          class="page"
          background
          @size-change="handleSizeChange"
          @current-change="currentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
          :current-page="page.pageNo"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import loadingStatusMixin from "@/mixins/loadingStatus";
import {
  settlementAdminPageOperation,
  settlementAdminSettlement,
  settlementAdminSettlementRecord
} from "../../service/moudules/promotionLinkApi";

export default {
  name: "settlementRecord",
  mixins: [loadingStatusMixin],
  data (){
    return {
      dataForm: {
        startDate: '', // 开始时间
        endDate: '', //结束时间
      },
      time: [],
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
    }
  },
  mounted() {
    this.getTable();
  },
  methods:{
    async getTable() {
      if ( this.hasLoadingState('getTable') ) return;
      try {
        this.addLoadingState('getTable');
        let params = {
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize
        };
        const searchFormValue = this.dataForm;
        Object.keys(searchFormValue).map((key) => {
          searchFormValue[key] && (params[key] = searchFormValue[key]);
        });
        let res = await settlementAdminSettlementRecord(params);
        this.removeLoadingState("getTable");
        if ( res.code == 200) {
          this.tableData = res.data.records;
          // this.page.pageNo = res.data.pages;
          this.page.pageNo = res.data.current;
          this.page.total = res.data.total;
        } else {
          this.removeLoadingState("getTable");
          this.$message.error(`${res.msg}！`);
        }
      } catch (e) {
        this.removeLoadingState("getTable");
      }
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNo = 1;
      this.getTable();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getTable();
    },
    getReset() {
      this.dataForm = {
        startDate: '', // 开始时间
        endDate: '', //结束时间
      };
      this.time = [];
      this.getTable();
    },
    getTime(val) {
      console.log("fddd:",val);
      if (val.length > 0) {
        let [x, y] = val;
        this.dataForm.startDate = x;
        this.dataForm.endDate = y;
      }
    },
    getRowRecord(row) {
      console.log("rowrow:",row);
      this.$confirm(`是否结算?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let params = {
          cleaningId: row.cleaningId
        }
        let res = await settlementAdminSettlement(params);
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: `结算成功!`
          });
          this.getTable();
        } else {
          this.$message.error(res.msg)
        }

      })
    }
  },


}
</script>

<style scoped lang="less">
.content{
  padding: 20px;
  margin: 10px;
  border: 1px solid #dcdfe6;
  text-align: initial;
  background-color: white;
  .con-tab{
    background-color: #e6a23c2b;
    padding: 10px 8px;
    border-radius: 8px;
    font-size: 14px;
    color: #e6a23c;
    margin-bottom: 20px;
  }
  .page {
    text-align: right;
    margin-top: 15px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar{
    width: 1px;
    height: 10px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: rgba(0,0,0,.2);
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: rgba(0,0,0,.1);
  }
  ::v-deep .el-table__header th.el-table__cell{
    height:50px;
    background-color:#fafafa;
    color:#333333;
    padding:0
  }
}

</style>