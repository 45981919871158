const mixin = {
  data() {
    return { loadingStatus: {} };
  },
  methods: {
    hasLoadingState(key) {
      return Boolean(this.loadingStatus[key]);
    },
    addLoadingState(key) {
      const loadingStatus = { ...this.loadingStatus };
      loadingStatus[key] = true;
      this.loadingStatus = loadingStatus;
    },
    removeLoadingState(key) {
      const loadingStatus = { ...this.loadingStatus };
      delete loadingStatus[key];
      this.loadingStatus = loadingStatus;
    },
  },
};

export default mixin;
