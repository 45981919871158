import '@/service/axios';
//游戏管理
export const promotionLinkGamesList = params => axios.post(
    "/promotionLink/games/list",
    params).then(data => data)
//员工推广
export const promotionEmpPromotionList = params => axios.post(
    "/promotionLink/empPromotion/list",
    params).then(data => data)
//充值统计
export const dataStatisticsRechargeStatisticsList = params => axios.post(
    "/dataStatistics/recharge/statistics/list",
    params).then(data => data)
//注册统计
export const dataStatisticsRegistrationStatisticsList = params => axios.post(
    "/dataStatistics/registration/statistics/list",
    params).then(data => data)
//员工统计
export const dataStatisticsEmpStatisticsList = params => axios.post(
    "/dataStatistics/emp/statistics/list",
    params).then(data => data)
//员工业绩
export const dataStatisticsEmpPerformanceList = params => axios.post(
    "/dataStatistics/emp/performance/list",
    params).then(data => data)
//充值明细
export const dataDetailRechargeDetailList = params => axios.post(
    "/dataDetail/recharge/detail/list",
    params).then(data => data)
//订单明细
export const dataStatisticsOrderDetailList = params => axios.post(
    "/dataDetail/order/detail/list",
    params).then(data => data)
//注册明细
export const dataStatisticsRegisterDetailList = params => axios.post(
    "/dataDetail/register/detail/list",
    params).then(data => data);
//注册明细-导出
export const dataStatisticsRegisterDetailExport = params => axios.post(
    "/dataDetail/extension/detail/export",
    params).then(data => data);

//推广明细
export const dataDetailExtensionDetailList = params => axios.post(
    "/dataDetail/extension/detail/list",
    params).then(data => data)
//规则列表
export const commissionRulesList = params => axios.post(
    "/commissionRules/list",
    params).then(data => data)
//添加规则
export const commissionRulesAdd = params => axios.post(
    "/commissionRules/add",
    params).then(data => data);
import cookieUtils from '@/util/cookieUtils'
let sessionId = cookieUtils.getCookie("sessionId");

//佣金结算
export const settlementAdminAdminPage = params => axios.post(
    `/settlementAdmin/admin/page`,
    params).then(data => data);
//支付宝账号管理-添加
export const accountAdminAlipayAccountAdmin = params => axios.post(
    `/accountAdmin/alipayAccountAdmin?sessionId=${sessionId}`,
    params).then(data => data);
//支付宝账号-列表
export const accountAdminAlipayAccountAdminList = params => axios.post(
    `/accountAdmin/alipayAccountAdmin/list?sessionId=${sessionId}`,
    params).then(data => data);

//银行账号管理-添加
export const accountAdminBankAccountAdmin = params => axios.post(
    `/accountAdmin/bankAccountAdmin`,
    params).then(data => data);
export const settlementAdminWithdrawDeposit = params => axios.post(
    `/settlementAdmin/withdrawDeposit`,
    params).then(data => data);
//银行账号管理-列表
export const accountAdminBankAccountAdminList = params => axios.post(
    `/accountAdmin/bankAccountAdmin/list`,
    params).then(data => data);

//会长-提现管理-提现列表
export const settlementAdminList = params => axios.post(
    `/settlementAdmin/list`,
    params).then(data => data);
//提现额度
export const accountAdminAvailableBalance = () => axios.get(
    `/accountAdmin/availableBalance/${sessionId}`).then(data => data);
//概况统计
export const overviewOverviewInfo = params => axios.get(
    `/overview/overviewInfo/${params.sessionId}`).then(data => data);
//查提现账户管理-列表
export const settlementAdminAccountAdmin = () => axios.get(
    `/settlementAdmin/accountAdmin/${sessionId}`).then(data => data);
//列表操作-{1、处理 2、驳回 3、取消}
export const settlementAdminPageOperation = params => axios.post(
    `/settlementAdmin/page/operation`,params).then(data => data);
//结算记录
export const settlementAdminSettlementRecord = params => axios.post(
    `/settlementAdmin/settlementRecord`,params).then(data => data);
//结算按钮
export const settlementAdminSettlement = params => axios.get(
    `/settlementAdmin/settlement/${params.cleaningId}`).then(data => data);

export const promotionLinkGamesPack = params => axios.post(
    `/promotionLink/games/pack`,params).then(data => data);
// 银行账户详情
export const bankAccountAdminInfo = () => axios.get(
    `/accountAdmin/bankAccountAdmin/info/${sessionId}`).then(data => data);
// 支付宝账户详情
export const alipayAccountAdminInfo = () => axios.get(
    `/accountAdmin/alipayAccountAdmin/info/${sessionId}`).then(data => data);

//下拉数据
export const commonInfo = () => axios.get(
    `/common/info/${sessionId}`).then(data => data)

    export const presidentInfo = () => axios.get(
        `/settlementAdmin/admin/presidentInfo`).then(data => data)
    